/**
 * Script Name: Hero Greeting Generator
 * Description: This script dynamically generates a greeting based on the current time of day and special holidays.
 *              It is designed to be embedded in web pages to provide a personalized greeting to visitors.
 * Author: Chris Stephens
 * Created Date: April 20, 2024
 * Last Modified Date: April 21, 2024
 */

// Daily Greetings Object
const greetings = {
  Monday: {
    morning: { text: "Diving into this week like:", emoji: "🤿" },
    afternoon: {
      text: "Afternoon hustle! Keeping the flow strong.",
      emoji: "💼",
    },
    evening: { text: "Monday's almost done, virtual high five!", emoji: "✋" },
  },
  Tuesday: {
    morning: { text: "Ready to launch into your Tuesday?", emoji: "🚀" },
    afternoon: { text: "That busy Tuesday afternoon vibe.", emoji: "🤹" },
    evening: { text: "Powering through the (tues)day!", emoji: "🔋" },
  },
  Wednesday: {
    morning: { text: "Halfway through the week, let's do this!", emoji: "💪" },
    afternoon: {
      text: "What's better than Taco Tuesday? WordPress Wednesday.",
      emoji: "🌮",
    },
    evening: { text: "Working late? So is our website.", emoji: "🌙" },
  },
  Thursday: {
    morning: { text: "Let's make some Thursday morning magic!", emoji: "🎩" },
    afternoon: {
      text: "It's thirsty Thursday, remember to hydrate.",
      emoji: "💧",
    },
    evening: { text: "Almost Friday, we'll cheers to that!", emoji: "🥂" },
  },
  Friday: {
    morning: { text: "Thank Gutenberg. It's Friday!", emoji: "🙏" },
    afternoon: {
      text: "It's Friday, we're in love (with websites).",
      emoji: "😍",
    },
    evening: { text: "Weekend's here! Catch ya on the flipside.", emoji: "✌️" },
  },
  Saturday: {
    morning: { text: "Saturday morning coffee & catch-up.", emoji: "☕" },
    afternoon: {
      text: "Enjoy your weekend website surfing sesh.",
      emoji: "🏄",
    },
    evening: {
      text: "Live from Kelowna... it's Saturday Niiiight!",
      emoji: "🎤",
    },
  },
  Sunday: {
    morning: {
      text: "Website updates? Easy like Sunday morning.",
      emoji: "🎵",
    },
    afternoon: {
      text: "Sunday funday, thanks for hanging with us.",
      emoji: "🤙",
    },
    evening: { text: "Resting up & ready to crush it this week.", emoji: "🛌" },
  },
  "New Year's Day": {
    text: "Cheers to new beginnings and goals!",
    emoji: "🎆",
  },
  "Canada Day": { text: "Celebrating our nation and future.", emoji: "🇨🇦" },
  "Labour Day": { text: "Saluting hard work and looking ahead.", emoji: "🛠" },
  Thanksgiving: {
    text: "Thankful for achievements and eager for more. Yum.",
    emoji: "🦃",
  },
  Christmas: {
    text: "Joyful holidays and a prosperous new year.",
    emoji: "🎄",
  },
  Halloween: { text: "Spooky fun and digital excitement today.", emoji: "🎃" },
  "Family Day": { text: "Cherishing family time and memories.", emoji: "👪" },
  "Valentine's Day": {
    text: "Spreading love and creativity this Valentine's Day!",
    emoji: "💘",
  },
};

// Holiday Greetings Object
const holidays = {
  "10/31": "Halloween",
  "02/14": "Valentine's Day",
  "12/25": "Christmas",
  "01/01": "New Year's Day",
  "07/01": "Canada Day",
  "02/15": "Family Day",
  "09/06": "Labour Day",
  "10/11": "Thanksgiving",
};

// Override greeting (comment out when not in use)
let overrideGreeting = {
  text: "Celebrating 10 years of Twirling! ☂️",
  emoji: "🎉",
};

// Set the greeting and emoji based on the current date and time and adjust the length based on our SVG.
document.addEventListener("DOMContentLoaded", function () {
  // Initialize date and time to determine the appropriate greeting
  const now = new Date();
  const dayOfWeek = now.toLocaleDateString("en-CA", { weekday: "long" });
  const hour = now.getHours();
  const timeOfDay = hour < 12 ? "morning" : hour < 18 ? "afternoon" : "evening";
  const today = now.toLocaleDateString("en-CA");

  // Use either the override greeting if available, a special holiday greeting, or a regular greeting based on the day and time
  const greeting =
    overrideGreeting || holidays[today] || greetings[dayOfWeek][timeOfDay];
  const text = greeting.text + "\u00A0\u00A0\u00A0"; // Append three non-breaking spaces
  const emoji = greeting.emoji;

  // Access SVG elements
  const textPathElement = document.getElementById("textPath");
  const emojiTextElement = document.getElementById("emojiText");
  const circlePathElement = document.getElementById("circlePath");

  // Set the text length to match the circumference of the circle path
  const circumference = circlePathElement.getTotalLength();
  textPathElement.setAttribute("textLength", circumference.toString());

  // Set a default font size for text and emoji
  textPathElement.style.fontSize = "28px"; // Default font size for text
  emojiTextElement.style.fontSize = "70px"; // Larger font size for emoji for better visibility

  // Update the SVG elements with the text and emoji
  textPathElement.textContent = text;
  emojiTextElement.textContent = emoji;
});
